import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar/Navbar';
import WorkItem from '../components/WorkItem/WorkItem';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import './styles/Work.css';

const Work = ({ works, categories }) => {
	const filteredCategories = categories.filter((category) => {
		for (let i = 0; i < works.length; i++) {
			if (works[i].category.name === category.name) {
				return category;
			}
		}
	});

	const [selectedCategory, setSelectedCategory] = useState(
		filteredCategories[0]?.name
	);
	const [toggleMenu, setToggleMenu] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 734);

	useEffect(() => {
		if (filteredCategories.length > 0) {
			setSelectedCategory(filteredCategories[0].name);
		}
	}, [categories, works]);

	const handleResize = () => {
		let resizeTimer = null;
		if (resizeTimer) {
			clearTimeout(resizeTimer);
		} else {
			resizeTimer = setTimeout(() => {
				if (window.innerWidth < 734) {
					setIsMobile(true);
				} else {
					setIsMobile(false);
				}
				resizeTimer = null;
			}, 600);
		}
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [isMobile]);

	return (
		<>
			<Navbar />
			<section className="work">
				<nav className="work__sidebar">
					<div className="position-fixed">
						<div className="work__sidebar-title">// Categories</div>
						<ul>
							{filteredCategories.length > 0 &&
								filteredCategories.map((c) => (
									<li key={c.id} onClick={() => setSelectedCategory(c.name)}>
										{c.name}
									</li>
								))}
						</ul>
					</div>

					{/* Mobile Render */}
					<div className="position-fixed-mobile">
						<div
							className="work__togglemenu-title"
							onClick={() => setToggleMenu(!toggleMenu)}
						>
							<div className="work__togglemenu-title-text">// Categories</div>
							{!toggleMenu ? (
								<BsArrowDownShort
									style={{ position: 'relative', bottom: '-5px' }}
								/>
							) : (
								<BsArrowUpShort
									style={{ position: 'relative', bottom: '-5px' }}
								/>
							)}
						</div>
						{toggleMenu && (
							<div className="work__togglemenu">
								<ul>
									{filteredCategories.length > 0 &&
										filteredCategories.map((c) => (
											<li
												key={c.id}
												onClick={() => setSelectedCategory(c.name)}
											>
												{c.name}
											</li>
										))}
								</ul>
							</div>
						)}
					</div>
				</nav>
				<div className="work__gallery-container">
					{filteredCategories.length > 0 &&
						filteredCategories.map((c) => (
							<div
								key={c.id}
								className={
									'work__gallery' + (selectedCategory === c.name ? '' : ' hide')
								}
								style={{
									gridTemplateRows: `repeat(${
										works.filter((w) => w.category.name === c.name).length
									}, ${isMobile ? '342px' : '684px'})`,
								}}
							>
								{works.length > 0 &&
									works
										.filter((w) => w.category.name === c.name)
										.reverse()
										.map((w) => <WorkItem key={w.id} work={w} />)}
							</div>
						))}
				</div>
			</section>
		</>
	);
};

export default Work;
