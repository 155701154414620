import React, { useState } from 'react';
import b64ToBlob from 'b64-to-blob';
import fileSaver from 'file-saver';
import axios from 'axios';

const Zip = ({ id, name, type, children }) => {
	const [downloading, setDownloading] = useState(false);

	const handleDownloadZip = async () => {
		setDownloading(true);
		const response = await axios.get(`/api/models/download/${id}/${type}`);
		try {
			const blob = b64ToBlob(response.data, 'application/zip');
			fileSaver.saveAs(blob, `${name}-${type}.zip`);
		} catch (err) {
			console.log(err);
		}
		setDownloading(false);
	};
	return React.cloneElement(children, {
		disabled: downloading,
		onClick: handleDownloadZip,
	});
};

export default Zip;
