import React, { useState, useEffect } from 'react';
import WishListCard from '../WishListCard/WishListCard';
import CompartirWishList from '../CompartirWishList/CompartirWishList';
import './WishList.css';

const WishList = ({
	wishList,
	models,
	deleteModel,
	openCloseWishList,
	isWishListOpen,
}) => {
	const [isCompartirOpen, setIsCompartirOpen] = useState(false);

	const handleCompartir = () => {
		setIsCompartirOpen(!isCompartirOpen);
	};

	return (
		<>
			<div className={`wishlist__modal ${isWishListOpen ? '' : 'hidden'}`}>
				{wishList !== null && wishList.length > 0 && (
					<button
						type="button"
						className="wishlist__compartir"
						onClick={() => handleCompartir()}
					>
						Compartir
					</button>
				)}
				<div className="wishlist__modal__cards">
					{wishList !== null &&
						wishList &&
						wishList.map((item, index) => {
							return (
								<WishListCard
									key={index}
									model={item}
									deleteModel={deleteModel}
								/>
							);
						})}
					{wishList && wishList.length === 0 && (
						<p className="wishlist__empty">[ Your wishlist is empty ]</p>
					)}
				</div>
			</div>
			<div className="wishlist-displax-flex">
				<button className="wishlist__button" onClick={openCloseWishList}>
					WISHLIST
				</button>
				<button
					className={`wishlist__filter-close ${isWishListOpen ? '' : 'hide'}`}
					onClick={openCloseWishList}
				>
					x
				</button>
			</div>
			{isCompartirOpen === true && (
				<CompartirWishList
					wishListModels={wishList}
					handleCompartir={handleCompartir}
				/>
			)}
		</>
	);
};

export default WishList;
