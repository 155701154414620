import React from 'react';
import './styles/404.css';
import { Link } from 'react-router-dom';

const Page404 = () => {
	return (
		<div className="page-404">
			<h1>404</h1>
			<h2>Pagina no encontrada</h2>
			<Link to="/" className="link-404">
				Volver al inicio
			</Link>
		</div>
	);
};

export default Page404;
