import React, { cloneElement, useEffect, useState } from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { convertToPath } from '../../utils/strUtils';
import MobileMenu from '../MobileMenu/MobileMenu';
import './AnimatedLayout.css';

const AnimatedLayout = ({ children, labels, models, isLoaded }) => {
	const [selectedTab, setSelectedTab] = useState(0);
	const [linksPosition, setLinksPosition] = useState([]);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 734);
	const location = useLocation();

	const handleLinkPosition = (i) => {
		const linkWidth = 32; // px
		const hasPassed = selectedTab > i;
		const tabDistance = Math.abs(i - selectedTab);
		const rightPosition = `${
			linkWidth * (children.length - 1 - selectedTab - tabDistance)
		}px`;

		const translateDistance = hasPassed
			? `-${window.innerWidth - linkWidth * (i + 1)}px`
			: '0px';

		const zIndex = children.length - i;
		return {
			right: hasPassed ? '0px' : rightPosition,
			transform: `translate3d(${translateDistance}, 0, 0)`,
			zIndex,
		};
	};

	const updateLinksPosition = () => {
		setLinksPosition(
			Array.from({ length: children.length }, (_, i) => handleLinkPosition(i))
		);
	};

	useEffect(() => {
		updateLinksPosition();
		// eslint-disable-next-line
	}, [selectedTab]);

	// Change to selected tab when user comes from an external link
	useEffect(() => {
		const path = location.pathname.substring(1, location.pathname.length);
		const pathWithoutSlash = path.endsWith('/')
			? path.substring(0, path.length - 1)
			: path;
		const index = labels.findIndex(
			(label) => convertToPath(label) === pathWithoutSlash
		);
		const isModelPage =
			models.findIndex((model) => model.path === pathWithoutSlash) !== -1;
		if (path === '') {
			setSelectedTab(0);
		} else if (pathWithoutSlash.match(/book-talent\/.+/) !== null) {
			setSelectedTab(1);
		} else if (index === -1) {
			setSelectedTab(-1); // Renders 404
		} else {
			setSelectedTab(index);
		}
		// eslint-disable-next-line
	}, [location]);

	const handleResize = () => {
		let resizeTimer = null;
		if (resizeTimer) {
			clearTimeout(resizeTimer);
		} else {
			resizeTimer = setTimeout(() => {
				updateLinksPosition();
				if (window.innerWidth < 734) {
					setIsMobile(true);
				} else {
					setIsMobile(false);
				}
				resizeTimer = null;
			}, 600);
		}
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [selectedTab]);

	return (
		<div className="animated-layout">
			{children &&
				React.Children.map(children, (child, i) => (
					<div
						className={isLoaded ? 'animated-item animated' : 'animated-item'}
						style={!isMobile ? linksPosition[i] : null}
					>
						<div
							className={
								isLoaded ? 'animated-content animated' : 'animated-content'
							}
							style={{ opacity: selectedTab === i ? 1 : 0 }}
							aria-hidden={selectedTab === i ? 'false' : 'true'}
							tabIndex={selectedTab === i ? '0' : '-1'}
						>
							{cloneElement(child)}
						</div>
						<Link
							to={`/${i === 0 ? '' : convertToPath(labels[i])}`}
							className="animated-nav-link custom-cursor-pointer"
							aria-current={selectedTab === i ? 'page' : null}
						>
							<div
								className={
									selectedTab !== i ? 'animated-link' : 'animated-link dark'
								}
								onClick={() => setSelectedTab(i)}
								role="button"
								aria-label={`Ir a la pestaña ${labels[i]}`}
							>
								<div>
									<span>{labels[i]}</span>
								</div>
							</div>
						</Link>
					</div>
				))}
			<MobileMenu
				labels={labels}
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
			>
				{children}
			</MobileMenu>
		</div>
	);
};

export default AnimatedLayout;
