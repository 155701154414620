import React from 'react';
import { Link } from 'react-router-dom';
import { convertToPath } from '../../utils/strUtils';
import PixelatedImage from '../PixelatedImage/PixelatedImage';
import './galleryItem.css';

const GalleryItem = ({
	src,
	name,
	onClick,
	srcType,
	wishList,
	setSelectedModel,
	model,
}) => {
	return (
		<div className="gallery__item">
			<Link to={`/book-talent/${convertToPath(name)}`}>
				<PixelatedImage
					src={src}
					alt=""
					srcType={srcType}
					h="300px"
					w="200px"
					onClick={() => setSelectedModel(model)}
				/>
			</Link>
			<div className="gallery__item-info">
				<div className="gallery__item-name">{name}</div>
				<div className="gallery__item-wish">
					<input
						type="checkbox"
						value={name}
						onChange={(e) => onClick(e)}
						checked={wishList.some((model) => model.name === name)}
					/>
				</div>
			</div>
		</div>
	);
};

export default GalleryItem;
