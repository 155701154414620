import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import ContactBanner from '../components/ContactBanner/ContactBanner';
import './styles/Contact.css';
import fotoContact from '../images/newFotoContact.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Contact = () => {
	return (
		<div className="fluid-container">
			<Navbar />
			<section className="contact_container">
				<div className="contact_container-photo">
					<figure className="contact_container-photo-container">
						<LazyLoadImage src={fotoContact} alt="contact" />
					</figure>
				</div>

				<div className="contact_container-info">
					<div className="contact_container-info-one">
						<h2 className="contact_container-info-title">Contact</h2>
						<p className="contact_container-info-bsas">// BUENOS AIRES</p>
						<p className="contact_container-info-tel">+549 11 4776 4070</p>
						<p className="contact_container-info-direc">
							Bonpland 2362, Palermo, Bs As, Argentina.
						</p>
						<a
							className="contact_container-info-instagram"
							href="https://www.instagram.com/streetagency.xyz/"
							target="_blank"
							rel="noopener noreferrer"
						>
							@streetagency.xyz
						</a>
					</div>

					<ContactBanner
						title={'New project'}
						contact={'work@streetagency.xyz'}
						mailTo={'mailto:work@streetagency.xyz'}
					/>

					<div className="contact_container-info-two">
						<ContactBanner
							title={'Join us'}
							contact={'street@streetagency.xyz'}
							mailTo={'mailto:street@streetagency.xyz'}
						/>
						<p className="contact_footer-desc">
							En St. Agency siempre estamos en busca de nuevos talentos. Si
							querés ser parte de nuestro scouting mandanos dos fotos tuyas, una
							plano medio y una cuerpo entero. No hace falta que uses make up,
							filtros, ni accesorios, así te podemos ver bien. Las fotos las
							podés sacar con el celular, te recomendamos usar luz natural y un
							fondo neutro.
						</p>
						<p className="contact_footer-desc">
							También mandanos tu altura, tu instagram, un teléfono y tu
							localidad. Todo a street@streetagency.xyz.
							<br /> Sólo si nos interesa tu perfil te vamos a contactar.
						</p>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Contact;
