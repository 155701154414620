import React from 'react';
import './ContactBanner.css';

const ContactBanner = ({ title, contact, styles, mailTo }) => {
	return (
		<div style={styles} className="contactBanner">
			<h3 className="contactBanner_title">{title}</h3>
			<a href={mailTo} className="contactBanner_contact">
				{contact}
			</a>
			<div className="contactBanner_line" />
		</div>
	);
};

export default ContactBanner;
