import React, { useEffect, useState } from 'react';
import './LoadingScreen.css';

const LoadingScreen = () => {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		let loadTimer = null;
		loadTimer = setTimeout(() => {
			setIsLoaded(true);
		}, 600);
		return () => clearTimeout(loadTimer);
	}, []);

	return (
		<div className={`loading-screen ${isLoaded ? 'hidden' : ''}`}>
			<div className="custom-loader"></div>
			<span>Cargando</span>
		</div>
	);
};

export default LoadingScreen;
