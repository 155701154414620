// Regex
const modelPageRegex = /\/book-talent\/.+/;
const contactPageRegex = /\/contact[\/]?(?!.*.$)/;
const workPageRegex = /\/work[\/]?(?!.*.$)/;
const talentPageRegex = /\/book-talent[\/]?(?!.*.$)/;

// Export
export const isModelPage = (pathname) => modelPageRegex.test(pathname);
export const isContactPage = (pathname) => contactPageRegex.test(pathname);
export const isWorkPage = (pathname) => workPageRegex.test(pathname);
export const isTalentPage = (pathname) => talentPageRegex.test(pathname);
