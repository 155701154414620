import React, { useState, useMemo, useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Zip from '../components/Zip/Zip';
import PolasCarousel from '../components/PolasCarousel/PolasCarousel';
import './styles/ModelPage.css';
import { Link } from 'react-router-dom';

const ModelPage = ({ selectedModel, wishList, setWishList }) => {
	const [bookOrientations, setBookOrientations] = useState([]);

	const getBookOrientation = async () => {
		const orientations = await Promise.all(
			selectedModel.book.map(async (item, index) => {
				let tempImg = new Image();
				tempImg.src = item.path;
				await tempImg.decode();
				const width = tempImg.naturalWidth;
				const height = tempImg.naturalHeight;
				tempImg = null;
				return width > height;
			})
		);
		setBookOrientations(orientations);
	};

	useEffect(() => {
		getBookOrientation();
	}, [selectedModel]);

	return (
		<article className="modelpage_scroll">
			<Navbar
				selectedModel={selectedModel}
				isModelPage
				setWishList={setWishList}
				wishList={wishList}
			/>
			<div className="modelpage__profile">
				<div className="modelpage__profile-container">
					<Link to={'/book-talent/'}>
						<button className="modelpage__profile-x">X</button>
					</Link>
					<figure className="modelpage__photo">
						<img src={selectedModel.photo.path} alt="model" />
					</figure>
					<div className="modelpage__data">
						<h2 className="model__name">{selectedModel.name}</h2>
						<div className="model__data-bottom">
							<div className="model__country">
								<p className="p-country">// {selectedModel.country}</p>
								<a
									href={`https://www.instagram.com/${selectedModel.instagram}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									INSTAGRAM
								</a>
							</div>
							<p className="p-medidas">
								<span>Altura&nbsp;{selectedModel.height}cm / </span>
								<br className="medium-show" />
								<span>Busto&nbsp; {selectedModel.bust}cm / </span>
								<br className="medium-show" />
								<span>Cintura&nbsp;{selectedModel.waist}cm / </span>
								<br className="medium-show" />
								<span>Cadera&nbsp;{selectedModel.hip}cm / </span>
								<br className="medium-show" />
								<span>Zapatos&nbsp; {selectedModel.shoeSize} / </span>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="modelpage__second-section">
				{selectedModel.polaroids.length > 0 && (
					<div className="modelpage__polas">
						<div className="polas__title">
							<p className="p-polas">// Polas</p>
							<Zip
								name={selectedModel.name}
								id={selectedModel.id}
								type={'polaroids'}
							>
								<button>DESCARGAR POLAS </button>
							</Zip>
						</div>
						<PolasCarousel
							polaroids={selectedModel.polaroids}
							name={selectedModel.name}
						/>
					</div>
				)}

				{selectedModel.book.length > 0 && (
					<div className="modelpage__book">
						<div className="book__title">
							<p className="p-book">// Book</p>
							<Zip
								name={selectedModel.name}
								id={selectedModel.id}
								type={'book'}
							>
								<button>DESCARGAR BOOK </button>
							</Zip>
						</div>
						<div className="book__gallery">
							{selectedModel.book.length > 0 &&
								selectedModel.book.map((item, index) => {
									const isHorizontal = bookOrientations[index];
									return (
										<div
											className="book__gallery-item"
											key={index}
											style={{
												gridColumn: isHorizontal ? 'span 2' : 'span 1',
											}}
										>
											<img
												src={item.path}
												alt={selectedModel.name}
												draggable="false"
											/>
										</div>
									);
								})}
						</div>
					</div>
				)}
			</div>
		</article>
	);
};

export default ModelPage;
