import React from 'react';
import { useDate } from '../../hooks/useDate';
import logo from '../../images/logo-st.png';
import UrbanScouting from '../UrbanScouting/UrbanScouting';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({
	topText,
	children,
	isHome,
	isModelPage,
	isTalents,
	selectedModel,
	wishList,
	setWishList,
}) => {
	const { time, date } = useDate();

	const addToWishlist = (model) => {
		if (wishList !== null) {
			let newWishList = [...wishList, model];
			let exists = wishList.some((item) => item.name === model.name);
			if (exists) return;
			setWishList(newWishList);
		} else {
			setWishList([model]);
		}
	};

	return (
		<nav className={isHome ? 'navbar__home' : 'navbar'}>
			<div className={isHome ? 'navbar__left opacity__0' : 'navbar__left'}>
				<Link to="/">
					<div className={isHome ? 'display-none' : 'navbar__logo'}>
						<img src={logo} alt="Street Agency" />
					</div>
				</Link>
				<div className="navbar__wishlist">{topText}</div>
			</div>
			<UrbanScouting />
			<div className="navbar__right">
				<div className="navbar__right-date">{children ? children : date}</div>
				<div className="navbar__right-time">{time} HS</div>
			</div>
		</nav>
	);
};

export default Navbar;
