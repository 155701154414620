import { useState, useEffect } from 'react';

export const useDate = () => {
	const locale = 'es';
	const [today, setDate] = useState(new Date());

	useEffect(() => {
		const timer = setInterval(() => {
			setDate(new Date());
		}, 1000);
		return () => {
			clearInterval(timer);
		};
	}, []);

	const convertirFecha = (fecha) => {
		let fechaString = fecha.toString();
		let fechaLenght = fechaString.length;
		return fechaLenght == 1 ? `0${fechaString}` : fechaString;
	};

	const day = today.toLocaleDateString(locale, { weekday: 'short' });
	const date = `${day}. ${today.getDate()} //  ${convertirFecha(
		today.getMonth()+1
	)}`;

	const time = today.toLocaleTimeString(locale, {
		hour: 'numeric',
		hour12: false,
		minute: 'numeric',
		second: 'numeric',
	});

	return {
		date,
		time,
	};
};
