import React, { useEffect, useState } from 'react';
import './styles/Talents.css';
import Navbar from '../components/Navbar/Navbar';
import GalleryItem from '../components/GalleryItem/GalleryItem';
import TalentFilter from '../components/TalentFilter/TalentFilter';
import WishList from '../components/WishList/WishList';

const Talents = ({
	modelsFromApi,
	setSelectedModel,
	wishList,
	setWishList,
	isTalentPage,
}) => {
	const [filteredModels, setFilteredModels] = useState(modelsFromApi);
	const [isOpen, setIsOpen] = useState(false);
	const [isWishListOpen, setIsWishListOpen] = useState(false);

	useEffect(() => {
		let modelInLocalStorage = JSON.parse(localStorage.getItem('wishList'));
		if (modelInLocalStorage) {
			setWishList(modelInLocalStorage);
		} else {
			setWishList([]);
		}
	}, []);

	useEffect(() => {
		setFilteredModels(modelsFromApi);
	}, [modelsFromApi]);

	useEffect(() => {
		if (!isTalentPage) {
			setIsWishListOpen(false);
			setIsOpen(false);
		}
	}, [isTalentPage]);

	// funcion para agregar modelo a localstorage.
	const handleCheckbox = (e) => {
		const model = modelsFromApi.find((i) => i.name === e.target.value);
		if (e.target.checked) {
			if (wishList.some((i) => i.name === model.name)) return;
			const newWishList = wishList.concat(model);
			setWishList(newWishList);
			localStorage.setItem('wishList', JSON.stringify(newWishList));
		} else {
			const newWishList = removeModelFromWishList(wishList, model.name);
			setWishList(newWishList);
			localStorage.setItem('wishList', JSON.stringify(newWishList));
		}
	};

	// funcion para eliminar modelo desde la wishlist
	const deleteFromWishList = (e) => {
		const newWishList = removeModelFromWishList(wishList, e.target.value);
		setWishList(newWishList);
		localStorage.setItem('wishList', JSON.stringify(newWishList));
	};

	const removeModelFromWishList = (arr, value) => {
		return arr.filter((i) => i.name !== value);
	};

	const openCloseWishList = () => {
		setIsWishListOpen(!isWishListOpen);
	};

	return (
		<>
			<Navbar
				topText={
					<WishList
						wishList={wishList}
						models={modelsFromApi}
						deleteModel={deleteFromWishList}
						isWishListOpen={isWishListOpen}
						setIsWishListOpen={setIsWishListOpen}
						openCloseWishList={openCloseWishList}
					/>
				}
			>
				<div className="navbar__filter-container">
					<button className="navbar__filter" onClick={() => setIsOpen(!isOpen)}>
						Filtros
					</button>
					<button
						className={`navbar__filter-close ${isOpen ? '' : 'hide'}`}
						onClick={() => setIsOpen(false)}
					>
						x
					</button>
				</div>
			</Navbar>
			<section className="talents">
				<h2 className="visuallyhidden">Talents</h2>
				{filteredModels.length === 0 && modelsFromApi.length > 0 && (
					<p className="no_filters">
						No se encontraron talentos con los filtros asignados.
					</p>
				)}
				<div className="gallery">
					{modelsFromApi.map((i, key) => (
						<div
							className="talent"
							key={key}
							style={{
								display: filteredModels.includes(i) ? 'block' : 'none',
							}}
						>
							<GalleryItem
								name={i.name}
								src={i.photo.path}
								srcType={i.photo.type}
								alt={i.name}
								onClick={handleCheckbox}
								wishList={wishList}
								model={i}
								setSelectedModel={setSelectedModel}
							/>
						</div>
					))}
				</div>
				<TalentFilter
					models={modelsFromApi}
					setFilteredModels={setFilteredModels}
					isOpen={isOpen}
				/>
			</section>
		</>
	);
};

export default Talents;
