import React, { useEffect, useState, useRef } from 'react';
import './styles/Home.css';
import Navbar from '../components/Navbar/Navbar';
import Arrow from '../images/vector.png';
import videoHomeDesktop from '../images/videoHomeDesktop.mp4';
import videoHomeMobile from '../images/videoHomeMobile.mp4';
import frameHomeVideoDesktop from '../images/frameHomeVideoDesktop.jpg';
import frameHomeVideoMobile from '../images/frameHomeVideoMobile.jpg';
import newHomeVideo from '../images/newHomeVideo.mp4';
import newHomeVideoCompressed from '../images/newHomeVideoCompressed.mp4';
import frameHomeNewVideo from '../images/frameHomeNewVideo.jpg';
import { useLocation } from 'react-router-dom';

const Home = () => {
	const [isHome, setIsHome] = useState(false);
	const sectionRef = useRef(null);
	const location = useLocation();
	const [isMobile, setIsMobile] = useState(window.innerWidth < 734);

	const handleResize = () => {
		let resizeTimer = null;
		if (resizeTimer) {
			clearTimeout(resizeTimer);
		} else {
			resizeTimer = setTimeout(() => {
				if (window.innerWidth < 734) {
					setIsMobile(true);
				} else {
					setIsMobile(false);
				}
				resizeTimer = null;
			}, 600);
		}
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [isMobile]);

	const checkLocation = (loc) => {
		if (loc === '/') {
			setIsHome(true);
		}
	};

	useEffect(() => {
		!isHome && checkLocation(location.pathname);
		if (sectionRef.current && location.pathname === '/') {
			sectionRef.current.scrollTop = 0;
		}
	}, [location]);

	return (
		<section className="home" ref={sectionRef}>
			<Navbar isHome={true} />
			<div className="home__video">
				<div className="home__video-title">St.&nbsp;Agency</div>
				{isHome && (
					<video
						autoPlay
						muted
						playsInline
						loop
						aria-label="Video mostrando distintos talentos con un texto que lee Street Agency sobre el mismo"
						src={isMobile ? newHomeVideoCompressed : newHomeVideo}
						poster={/* isMobile ? frameHomeVideoMobile : frameHomeVideoDesktop */frameHomeNewVideo} 
					/>
				)}
			</div>
			<div className="home__about">
				<p>
					Somos una agencia de modelos con sede en Buenos&nbsp;Aires. Desarrollamos a nuestros modelos para que puedan desempeñarse en la industria de la moda y la publicidad, tanto nacional como internacional. 
					<br />
					<br/>
					Hacemos street casting porque creemos que la publicidad y la moda son mas efectivas cuando la protagonizan personas&nbsp;reales. 
					<br />
					<br />
					Trabajamos en conjunto con{' '}
					<span className="about__underline">
						<a
							href="https://www.saigoncasting.com/es"
							aria-label="Más información sobre Saigon"
							target="_blank"
							rel="noopener noreferrer"
						>
							Saigon&nbsp;
							<img src={Arrow} alt="arrow icon" />
						</a>
					</span>
					 . Producimos castings para todo tipo de proyectos de publicidad, ficción, videoclips y&nbsp;moda.- 
				</p>
			</div>
		</section>
	);
};

export default Home;
