import React from 'react';
import { convertToPath } from '../../utils/strUtils';
import './CompartirWishList.css';

const CompartirWishList = ({ wishListModels, handleCompartir }) => {
	// esto previene que el evento click en el fondo se propague al padre y se cierre el modal
	const stopPropagation = (e) => {
		e.stopPropagation();
	};

	const createURL = (model) =>
		`https://streetagency.xyz/book-talent/${convertToPath(model.name)}`;

	const copiarAlPortaPapeles = () => {
		// array con las urls de los modelos en wishlist
		let arrayUrls = wishListModels.map((model) => createURL(model));

		// cambiar el separador de comas por "|"
		arrayUrls = arrayUrls.join(' | ');

		// copiar en portapapeles
		let aux = document.createElement('input');
		aux.setAttribute('value', arrayUrls);
		document.body.appendChild(aux);
		aux.select();
		document.execCommand('copy');
		document.body.removeChild(aux);
	};

	return (
		<div className="background__grey" onClick={() => handleCompartir()}>
			<div className="compartir__container" onClick={stopPropagation}>
				<p className="compartir__title">// Wishlist</p>
				<div className="compartir_ul-container">
					<ul className="compartir__models-list">
						{wishListModels.map((model, index) => {
							return (
								<li className="model__url" key={index}>
									{createURL(model)}
								</li>
							);
						})}
					</ul>
				</div>
				<div className="compartir__bottom">
					<button type="button" onClick={copiarAlPortaPapeles}>
						COPIAR
					</button>
					<button type="button" onClick={handleCompartir}>
						CERRAR
					</button>
				</div>
			</div>
		</div>
	);
};

export default CompartirWishList;
