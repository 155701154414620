import React, { useState } from 'react';
import './collapseContainer.css';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';

const CollapseContainer = ({ label, children, htmlFor }) => {
	const [show, setShow] = useState(false);

	const handleToggle = () => setShow(!show);
	const maxHeight = !show
		? '0px'
		: label.toLowerCase() === 'hobbies' || label.toLowerCase() === 'etnia'
		? '1000px'
		: '300px';

	return (
		<div className="colapse">
			<div className="div_container" onClick={handleToggle}>
				{htmlFor ? (
					<label htmlFor={htmlFor} style={{ letterSpacing: '3px' }}>
						{label}
					</label>
				) : (
					<p htmlFor={htmlFor} style={{ letterSpacing: '3px' }}>
						{label}
					</p>
				)}
				{!show ? <BsArrowDownShort /> : <BsArrowUpShort />}
			</div>
			<div
				style={{
					maxHeight,
					overflow: 'hidden',
					marginBottom: show ? '1rem' : '0',
					paddingTop: show ? '1rem' : '0',
					transition: 'all 0.3s ease-in-out',
				}}
				className={`filter_content ${show ? 'show' : ''}`}
			>
				{children}
			</div>
		</div>
	);
};

export default CollapseContainer;
