import React from 'react';
import './WishListCard.css';
import { RxCross1 } from 'react-icons/rx';
import { useEffect } from 'react';

const WishListCard = ({ model, deleteModel }) => {
	return (
		<div className="wishlistcard">
			<div className="wishlistcard__left">
				<button value={model.name} onClick={(e) => deleteModel(e)}>
					X
				</button>
			</div>
			<div className="wishlistcard__right">
				<figure className="wishlistcard__image">
					{model.photo.type.includes('video') ? (
						<video
							muted
							autoPlay
							loop
							src={model.photo.path}
							alt={model.name}
						/>
					) : (
						<img src={model.photo.path} alt={model.name} />
					)}
				</figure>
				<p>{model.name}</p>
			</div>
		</div>
	);
};

export default WishListCard;
