import React from 'react';
import './UrbanScouting.css';

const UrbanScouting = ({ isHome }) => {
	return (
		<div className="urban__scouting">
			<span>#URBANSCOUTING</span>
		</div>
	);
};

export default UrbanScouting;
