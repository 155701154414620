import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { pagesHeadInfo, description, keywords } from './constants/headInfo';
// Pages
import ModelPage from './pages/ModelPage';
import Talents from './pages/Talents';
import Work from './pages/Work';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Page404 from './pages/404';
// Components
import AnimatedLayout from './components/AnimatedLayout/AnimatedLayout';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
// Utils
import { convertToPath } from './utils/strUtils';
import {
	isModelPage as isModelPageCheck,
	isWorkPage as isWorkPageCheck,
	isTalentPage as isTalentPageCheck,
	isContactPage as isContactPageCheck,
} from './utils/routerUtils';
// Styles
import './App.css';

const App = () => {
	const location = useLocation();
	const [models, setModels] = useState([]);
	const [selectedModel, setSelectedModel] = useState(null);
	const [categories, setCategories] = useState([]);
	const [works, setWorks] = useState([]);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [isWorkPage, setIsWorkPage] = useState(
		isWorkPageCheck(location.pathname)
	);
	const [wishList, setWishList] = useState([]);
	const [isTalentPageActive, setIsTalentPageActive] = useState(
		isTalentPageCheck(location.pathname)
	);
	const [isContactPage, setIsContactPage] = useState(
		isContactPageCheck(location.pathname)
	);
	const [isModelPage, setIsModelPage] = useState(
		isModelPageCheck(location.pathname)
	);
	const [isTalentPage, setIsTalentPage] = useState(
		isTalentPageCheck(location.pathname)
	);
	const [is404, setIs404] = useState(false);
	const [pageTitle, setPageTitle] = useState('Street Agency');

	// Fetch Model data from API
	useEffect(() => {
		const getModels = async () => {
			const response = await axios.get('/api/models');
			const modelsFromApi = response.data;
			const modelsWithAge = modelsFromApi.map((model) => {
				return {
					...model,
					age: getAge(model.birthDate),
				};
			});
			const modelsOrderedByAlphabetic = modelsWithAge.sort((a,b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
			setModels(modelsOrderedByAlphabetic);
			setIsFirstLoad(false);
		};
		getModels();
	}, []);

	// Manage Lazy Loading of Work and Talents pages based on URL
	useEffect(() => {
		!isTalentPageActive &&
			setIsTalentPageActive(isTalentPageCheck(location.pathname));
		!isWorkPage && setIsWorkPage(isWorkPageCheck(location.pathname));
		setIsModelPage(isModelPageCheck(location.pathname));
		!isContactPage && setIsContactPage(isContactPageCheck(location.pathname));
		if (!isTalentPageCheck(location.pathname) && selectedModel) {
			setSelectedModel(null);
		}

		if (isTalentPageCheck(location.pathname)) {
			setIsTalentPage(true);
		} else {
			setIsTalentPage(false);
		}
	}, [location]);

	useEffect(() => {
		// Recursive erase trailing slash
		const eraseTrailingSlash = (path) => {
			if (path[path.length - 1] === '/') {
				return eraseTrailingSlash(path.slice(0, -1));
			}
			return path;
		};
		if (isModelPage || isModelPageCheck(location.pathname)) {
			const modelPath = location.pathname.replace('/book-talent/', '');
			const pathWithoutTrailingSlash = eraseTrailingSlash(modelPath);
			const model = models.find(
				(model) => convertToPath(model.name) === pathWithoutTrailingSlash
			);
			setSelectedModel(model);
		}
		setIsModelPage(isModelPageCheck(location.pathname));
	}, [models, location]);

	useEffect(() => {
		const validPath = [
			'/work',
			'/work/',
			'/book-talent',
			'/book-talent/',
			'/contact',
			'/contact/',
			'/',
		];

		const isModelPage = isModelPageCheck(location.pathname);
		const condition =
			!isModelPage && !isFirstLoad && !validPath.includes(location.pathname);

		condition ? setIs404(true) : setIs404(false);
	}, [selectedModel, models, isFirstLoad, location]);

	const getPageTitle = () => {
		const path = location.pathname;
		if (
			(isModelPage || !!location.pathname.match(/\/book-talent\/.+/)) &&
			selectedModel
		) {
			return selectedModel.name + ' | Street Agency';
		} else if (pagesHeadInfo[path] !== undefined) {
			return pagesHeadInfo[path].title;
		}
		return 'Street Agency';
	};

	useEffect(() => {
		const getWorks = async () => {
			const response = await fetch('/api/works');
			const works = await response.json();
			setWorks(works);
		};
		const getCategories = async () => {
			const response = await fetch('/api/work-categories');
			const categories = await response.json();
			setCategories(categories);
		};
		getWorks();
		getCategories();
	}, []);

	useEffect(() => {
		setPageTitle(getPageTitle());
	}, [location, selectedModel]);

	if (is404) {
		return <Page404 />;
	}

	// funcion obtener fecha y pasarla a edad
	const getAge = (dateString) => {
		let today = new Date();
		let birthDate = new Date(dateString);
		let age = today.getFullYear() - birthDate.getFullYear();
		let MonthDiff = today.getMonth() - birthDate.getMonth();
		if (
			MonthDiff < 0 ||
			(MonthDiff === 0 && today.getDate() < birthDate.getDate())
		) {
			age--;
		}
		return age;
	};

	return (
		<HelmetProvider>
			<div className="custom-cursor">
				<Helmet>
					<title>{pageTitle}</title>
					<meta name="description" content={description} />
					<meta name="keywords" content={keywords} />
					<meta name="author" content="NFSB - Studio" />
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1.0"
					/>
					<meta charSet="utf-8" />
					<meta name="og:title" content={pageTitle} />
					<meta name="og:description" content={description} />
					<meta name="og:image" content="/og.jpg" />
				</Helmet>
				{isFirstLoad && <LoadingScreen />}
				<h1 className="visuallyhidden">Street Agency</h1>
				<AnimatedLayout
					labels={['Home', 'Book Talent', 'Work', 'Contact']}
					models={models}
					isLoaded={!isFirstLoad}
				>
					<Home />
					{selectedModel && !isTalentPage ? (
						<ModelPage
							selectedModel={selectedModel}
							models={models}
							wishList={wishList}
							setWishList={setWishList}
						/>
					) : (
						<Talents
							setSelectedModel={setSelectedModel}
							modelsFromApi={models}
							wishList={wishList}
							setWishList={setWishList}
							isTalentPage={isTalentPage}
						/>
					)}
					<Work works={works} categories={categories} />
					<Contact />
				</AnimatedLayout>
			</div>
		</HelmetProvider>
	);
};

export default App;
