export const pagesHeadInfo = {
	'/': {
		title: 'Street Agency',
	},
	'/book-talent': {
		title: 'Book Talent - Street Agency',
	},
	'/work': {
		title: 'Work - Street Agency',
	},
	'/contact': {
		title: 'Contact - Street Agency',
	},
};

export const keywords =
	'agencia de modelos, servicio de modelaje, agencia, publicidad, produccion de publicidades, modelos publicidad, representante de modelos, scouting de modelos, scouting argetina, casting services';

export const description =
	'Somos una agencia en busca de una nueva generación de modelos que prioriza y celebra la diversidad, la autenticidad y el caracter.';
