import React, { useState } from 'react';
import Carousel from 'react-simply-carousel';
import './PolasCarousel.css';
import { HiArrowLongRight, HiArrowLongLeft } from 'react-icons/hi2';
import { useEffect } from 'react';

function PolasCarousel({ polaroids, name }) {
	const [activeSlide, setActiveSlide] = useState(0);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 734);

	if (polaroids.length === 0) return null;

	const handleResize = () => {
		let resizeTimer = null;
		if (resizeTimer) {
			clearTimeout(resizeTimer);
		} else {
			resizeTimer = setTimeout(() => {
				if (window.innerWidth < 734) {
					setIsMobile(true);
				} else {
					setIsMobile(false);
				}
				resizeTimer = null;
			}, 600);
		}
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [isMobile]);

	return (
		<div>
			<Carousel
				containerProps={{
					style: {
						justifyContent: 'center',
						userSelect: 'text',
					},
				}}
				activeSlideIndex={activeSlide}
				onRequestChange={setActiveSlide}
				forwardBtnProps={{
					show: !isMobile,
					children: <HiArrowLongRight fontSize={35} />,
					className: 'forwardBtn',
					style: {
						width: 60,
						height: 60,
						minWidth: 60,
						alignSelf: 'center',
						cursor: 'pointer',
					},
				}}
				backwardBtnProps={{
					show: !isMobile,
					children: <HiArrowLongLeft fontSize={35} />,
					className: 'backwardBtn',
					style: {
						width: 60,
						height: 60,
						minWidth: 60,
						alignSelf: 'center',
						cursor: 'pointer',
					},
				}}
				dotsNav={{
					show: isMobile,
					itemBtnProps: {
						style: {
							marginTop: '1rem',
							height: 16,
							width: 16,
							borderRadius: '50%',
							border: 0,
						},
					},
					containerProps: {
						style: {
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							gap: '5px',
						},
					},
					activeItemBtnProps: {
						style: {
							marginTop: '1rem',
							height: 16,
							width: 16,
							borderRadius: '50%',
							border: 0,
							background: 'black',
						},
					},
				}}
				itemsToShow={isMobile ? 1 : 2}
				speed={400}
			>
				{polaroids.map((item, index) => (
					<div className="polas__gallery-item" key={index}>
						<img src={item.path} alt={name} draggable="false" />
					</div>
				))}
			</Carousel>
		</div>
	);
}

export default PolasCarousel;
