import React, { useState, useEffect } from 'react';
import MultiRangeSlider from '../MultiRangeSlider/MultiRangeSlider';
import CollapseContainer from '../CollapseContainer/CollapseContainer';
import axios from 'axios';
import { convertToPath } from '../../utils/strUtils';

const TalentFilter = ({ models, setFilteredModels, isOpen }) => {
	const [hobbies, setHobbies] = useState([]);
	const [filter, setFilter] = useState({});

	useEffect(() => {
		const getHobbies = async () => {
			const response = await axios.get('/api/hobbies');
			setHobbies(response.data);
		};
		getHobbies();
	}, []);

	useEffect(() => {
		const selectKeys = [
			'gender',
			'eyeColor',
			'category',
			'hairLength',
			'hairColor',
			'hairLength',
			'ethnicity',
			'hobbies'
		];
		const minMaxKeys = ['age', 'weight', 'height', 'shoeSize'];
		const filterFunctions = {
			filterSelector: (item, field) => {
				return filter[field].some((i) => i === item[field]);
			},
			filterMinMax: (item, field) => {
				return (
					(item[field] >= filter[field].min &&
						item[field] <= filter[field].max) ||
					!item[field]
				);
			},
			filterName: (item) => {
				if (!filter.name || filter.name.length === 0) return true;
				return item.name.toLowerCase().includes(filter.name.toLowerCase());
			},
			filterHobbies: (item) => {
				if (!filter.hobbies || filter.hobbies.length === 0) return true;
				return item.hobbies.some(h => filter.hobbies.includes(h.name))
			}
		};
		if (Object.keys(filter).length > 0) {
			let result = [...models];
			Object.keys(filter).map((field) => {
				if (field.length > 0) {
					let filterMethod = null;
					if (minMaxKeys.includes(field))
						filterMethod = filterFunctions.filterMinMax;
					if (field === 'name') filterMethod = filterFunctions.filterName;
					if (selectKeys.includes(field))
						filterMethod = field === "hobbies" ? filterFunctions.filterHobbies : filterFunctions.filterSelector;
					result = result.filter((item) => filterMethod(item, field));
				}
			});
			setFilteredModels(result);
		} else {
			setFilteredModels(models);
		}
	}, [filter, models]);

	const handleCheckbox = (e, field) => {
		const fieldValue = filter[field] || [];
		if (e.target.checked) {
			setFilter((prevState) => ({
				...prevState,
				[field]: fieldValue.concat(e.target.value),
			}));
		} else {
			const newValue = fieldValue.filter((i) => i !== e.target.value);
			if (newValue.length === 0) {
				const { [field]: value, ...rest } = filter;
				setFilter(rest);
			} else {
				setFilter((prevState) => ({ ...prevState, [field]: newValue }));
			}
		}
	};

	const handleMultiSlider = ({ min, max, field }) => {
		setFilter((prevState) => ({ ...prevState, [field]: { min, max } }));
	};

	return (
		<div className={`sidebar_container ${isOpen ? '' : 'hidden'}`}>
			<CollapseContainer label={'NOMBRE'} htmlFor="name">
				<div className="item_container">
					<input
						className="input_nombre"
						type="text"
						id="name"
						placeholder="Ej: Tina"
						onChange={(e) =>
							setFilter((prevState) => ({
								...prevState,
								name: e.target.value,
							}))
						}
					/>
				</div>
			</CollapseContainer>
			<CollapseContainer label={'EDAD'} htmlFor="edad">
				<div className="item_container">
					<MultiRangeSlider
						min={0}
						max={80}
						onChange={handleMultiSlider}
						field={'age'}
						mesureUnit={'a'}
						id="edad"
					/>
				</div>
			</CollapseContainer>
			<CollapseContainer label={'GENERO'}>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="hombre"
						id="hombre"
						onChange={(e) => handleCheckbox(e, 'gender')}
					/>
					<label htmlFor="hombre">hombre</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="mujer"
						onChange={(e) => handleCheckbox(e, 'gender')}
						id="mujer"
					/>
					<label htmlFor="mujer">mujer</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="otro"
						onChange={(e) => handleCheckbox(e, 'gender')}
						id="gender-fluid"
					/>
					<label htmlFor="gender-fluid">otro</label>
				</div>
			</CollapseContainer>
			<CollapseContainer label={'CATEGORIAS'}>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="new face"
						id="new-face"
						onChange={(e) => handleCheckbox(e, 'category')}
					/>
					<label htmlFor="new-face">new face</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="development"
						onChange={(e) => handleCheckbox(e, 'category')}
						id="development"
					/>
					<label htmlFor="development">development</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="exclusive"
						onChange={(e) => handleCheckbox(e, 'category')}
						id="exclusive"
					/>
					<label htmlFor="exclusive">exclusive</label>
				</div>
			</CollapseContainer>
			<CollapseContainer label={'ALTURA'} htmlFor="altura">
				<MultiRangeSlider
					min={40}
					max={210}
					onChange={handleMultiSlider}
					field={'height'}
					mesureUnit={'cm'}
					id="altura"
				/>
			</CollapseContainer>
			<CollapseContainer label={'PESO'} htmlFor="peso">
				<MultiRangeSlider
					min={0}
					max={300}
					onChange={handleMultiSlider}
					field={'weight'}
					mesureUnit={'kg'}
					id="peso"
				/>
			</CollapseContainer>
			<CollapseContainer label={'ZAPATOS'} htmlFor="zapato">
				<MultiRangeSlider
					min={0}
					max={50}
					onChange={handleMultiSlider}
					field={'shoeSize'}
					id="zapato"
				/>
			</CollapseContainer>
			<CollapseContainer label={'OJOS'}>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="celeste"
						id="celeste"
						onChange={(e) => handleCheckbox(e, 'eyeColor')}
					/>
					<label htmlFor="celeste">celeste</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="verde"
						onChange={(e) => handleCheckbox(e, 'eyeColor')}
						id="verde"
					/>
					<label htmlFor="verde">verde</label>
				</div>

				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="marrón"
						onChange={(e) => handleCheckbox(e, 'eyeColor')}
						id="marron"
					/>
					<label htmlFor="marron">marron</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="heterocromía"
						onChange={(e) => handleCheckbox(e, 'eyeColor')}
						id="heterocromia"
					/>
					<label htmlFor="heterocromia">heterocromia</label>
				</div>
			</CollapseContainer>
			<CollapseContainer label={'LARGO DE CABELLO'}>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="rapado"
						onChange={(e) => handleCheckbox(e, 'hairLength')}
						id="rapado"
					/>
					<label htmlFor="rapado">rapado</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="corto"
						onChange={(e) => handleCheckbox(e, 'hairLength')}
						id="corto"
					/>
					<label htmlFor="corto">corto</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="medio"
						onChange={(e) => handleCheckbox(e, 'hairLength')}
						id="medio"
					/>
					<label htmlFor="medio">medio</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="largo"
						onChange={(e) => handleCheckbox(e, 'hairLength')}
						id="largo"
					/>
					<label htmlFor="largo">largo</label>
				</div>
			</CollapseContainer>
			<CollapseContainer label={'COLOR DE CABELLO'}>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="rubio"
						onChange={(e) => handleCheckbox(e, 'hairColor')}
						id="rubio"
					/>
					<label htmlFor="rubio">rubio</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="castaño"
						onChange={(e) => handleCheckbox(e, 'hairColor')}
						id="castaño"
					/>
					<label htmlFor="castaño">castaño</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="morocho"
						onChange={(e) => handleCheckbox(e, 'hairColor')}
						id="morocho"
					/>
					<label htmlFor="morocho">morocho</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="colorado"
						onChange={(e) => handleCheckbox(e, 'hairColor')}
						id="colorado"
					/>
					<label htmlFor="colorado">colorado</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="blanco"
						onChange={(e) => handleCheckbox(e, 'hairColor')}
						id="blanco"
					/>
					<label htmlFor="blanco">blanco</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="fantasía"
						onChange={(e) => handleCheckbox(e, 'hairColor')}
						id="fantasia"
					/>
					<label htmlFor="fantasia">fantasía</label>
				</div>
			</CollapseContainer>
			<CollapseContainer label={'ETNIA'}>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="caucásica"
						onChange={(e) => handleCheckbox(e, 'ethnicity')}
						id="caucasica"
					/>
					<label htmlFor="caucasica">caucásica</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="latina"
						id="latina"
						onChange={(e) => handleCheckbox(e, 'ethnicity')}
					/>
					<label htmlFor="latina">latina</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="afro"
						id="afro"
						onChange={(e) => handleCheckbox(e, 'ethnicity')}
					/>
					<label htmlFor="afro">afro</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="oriental"
						onChange={(e) => handleCheckbox(e, 'ethnicity')}
						id="oriental"
					/>
					<label htmlFor="oriental">oriental</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="hispana"
						onChange={(e) => handleCheckbox(e, 'ethnicity')}
						id="hispana"
					/>
					<label htmlFor="hispana">hispana</label>
				</div>
				<div className="item_container">
					<input
						className="input_checkbox"
						type="checkbox"
						value="medio oriente"
						id="medio-oriente"
						onChange={(e) => handleCheckbox(e, 'ethnicity')}
					/>
					<label htmlFor="medio-oriente">medio oriente</label>
				</div>
			</CollapseContainer>
			<CollapseContainer label={'HOBBIES'}>
				{hobbies.map((i, key) => {
					return (
						<div className="item_container" key={key}>
							<input
								className="input_checkbox"
								type="checkbox"
								value={i.name}
								id={convertToPath(i.name)}
								onChange={(e) => handleCheckbox(e, 'hobbies')}
							/>
							<label htmlFor={convertToPath(i.name)}>{i.name}</label>
						</div>
					);
				})}
			</CollapseContainer>
		</div>
	);
};

export default TalentFilter;
