import React, { useRef, useEffect, useState } from 'react';
import './PixelatedImage.css';

const Image = ({ src, h, w, alt, isVisible }) => {
	const size = 1.2; // Pixel size
	if (!isVisible) return null;
	return (
		<>
			<svg style={{ width: '100%', height: '100%' }}>
				<filter id="pixelate" x="0" y="0">
					<feFlood x={1} y={1} height={1} width={1} />
					<feComposite width={size * 2} height={size * 2} />
					<feTile result="a" />
					<feComposite in="SourceGraphic" in2="a" operator="in" />
					<feMorphology operator="dilate" radius={size} />
				</filter>
				<image
					width="100%"
					height="100%"
					preserveAspectRatio="xMidYMid slice"
					filter="url(#pixelate)"
					href={src}
					aria-hidden="true"
					draggable="false"
				/>
			</svg>
			<img
				src={src}
				height={h || 'auto'}
				width={w || 'auto'}
				alt={alt}
				aria-hidden="true"
				draggable="false"
			/>
		</>
	)
}


const PixelatedImage = ({ src, h, w, alt, srcType, name, ...props }) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const imgRef = useRef(null);

	if (!src) return null;

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting && !isVisible) {
						setIsVisible(true);
					}
				});
			},
			{
				root: null,
				rootMargin: '200px 0px',
				threshold: 0,
			}
		);

		if (imgRef.current) {
			observer.observe(imgRef.current);
		}

		return () => {
			if (imgRef.current) {
				observer.unobserve(imgRef.current);
			}
		};
	}, [imgRef, isVisible]);


	return (
		<div
			className={`pixelated-image ${isHovered ? 'hovered' : ''}`}
			role="img"
			{...props}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			ref={imgRef}
		>
			{srcType !== undefined && srcType.includes('video') ? (
				<video
					src={src}
					height={h || 'auto'}
					width={w || 'auto'}
					alt={alt || 'Video de un modelo'}
					aria-hidden="true"
					muted
					autoPlay
					loop
					draggable="false"
				/>
			) : (
				<Image src={src} h={h} w={w} alt={alt} isVisible={isVisible} />
			)}
		</div>
	);
};

export default PixelatedImage;
