import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { convertToPath } from '../../utils/strUtils';
import './MobileMenu.css';

const MobileMenu = ({ children, labels, selectedTab, setSelectedTab }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
			<button
				className="mobile-menu__button"
				onClick={() => setIsOpen(!isOpen)}
				aria-label={isOpen ? 'Cerrar menú' : 'Abrir menú'}
			>
				{isOpen ? 'Cerrar' : 'Menú'}
			</button>
			<div
				className="mobile-menu__links"
				aria-hidden={isOpen ? 'false' : 'true'}
				tabIndex={isOpen ? '0' : '-1'}
			>
				{React.Children.map(children, (_, i) => (
					<Link
						to={`/${i === 0 ? '' : convertToPath(labels[i])}`}
						className={
							selectedTab === i
								? 'mobile-menu__nav-link dark'
								: 'mobile-menu__nav-link'
						}
						aria-current={selectedTab === i ? 'page' : null}
					>
						<div
							onClick={() => {
								setSelectedTab(i);
								setIsOpen(false);
							}}
							role="button"
							aria-label={`Ir a la pestaña ${labels[i]}`}
						>
							<div>
								<span>{labels[i]}</span>
							</div>
						</div>
					</Link>
				))}
			</div>
		</div>
	);
};

export default MobileMenu;
