import React from 'react';
import PixelatedImage from '../PixelatedImage/PixelatedImage';
import './WorkItem.css';

const WorkItem = ({ work }) => {
	return (
		<div className="work__gallery-item">
			<a href={work.url} target="_blank" rel="nofollow">
				<PixelatedImage
					src={work.thumbnail?.path}
					alt={work.title}
					srcType={work.thumbnail?.type}
				/>
				<div className="work__gallery-item work-info">
					<h3 className="work__gallery-item work-title">{work.title}</h3>
					<p className="work__gallery-item work-description">{work.director}</p>
				</div>
			</a>
		</div>
	);
};

export default WorkItem;
